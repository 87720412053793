var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5", attrs: { role: "group" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "8", cols: "4" } },
            [
              _vm.validationDropdown
                ? _c(
                    "b-dropdown",
                    {
                      ref: "dropdown-integrator",
                      staticClass: "m-2",
                      attrs: {
                        id: "dropdown-integrator",
                        "menu-class": "form-user",
                        right: "",
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "button btn-blue-dark ml-0",
                                    attrs: { variant: "outline-primary" }
                                  },
                                  [
                                    _vm.selectedIntegrator.length
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "admin.integrator.editIntegrator"
                                              )
                                            ) + " +"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "admin.integrator.createIntegrator"
                                              )
                                            ) + " +"
                                          )
                                        ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3384681327
                      )
                    },
                    [
                      _c(
                        "b-dropdown-text",
                        { staticClass: "my-3 text-header-user" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "person-plus",
                              "aria-hidden": "true"
                            }
                          }),
                          _vm.selectedIntegrator.length
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("admin.integrator.editIntegrator")
                                    )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("admin.integrator.createIntegrator")
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-form",
                        [
                          _c("b-form-input", {
                            staticClass: "form-input-style",
                            attrs: {
                              size: "sm",
                              placeholder: this.$i18n.t(
                                "admin.integrator.company"
                              ),
                              state: _vm.validationCompany
                            },
                            model: {
                              value: _vm.form.company,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "company", $$v)
                              },
                              expression: "form.company"
                            }
                          }),
                          _c("b-form-input", {
                            staticClass: "form-input-style",
                            attrs: {
                              size: "sm",
                              placeholder: this.$i18n.t(
                                "admin.integrator.name"
                              ),
                              state: _vm.validationintegrator,
                              disabled: _vm.isEdit
                            },
                            model: {
                              value: _vm.form.integrador,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "integrador", $$v)
                              },
                              expression: "form.integrador"
                            }
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "justify-content-end buttons-dropdown"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-blue-dark-outline ml-0",
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("admin.users.cancel")) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.selectedIntegrator.length
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled: _vm.isLoading || !_vm.isValid
                                      },
                                      on: { click: _vm.handleEditUser }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.save")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled: _vm.isLoading || !_vm.isValid
                                      },
                                      on: { click: _vm.handleCreateUser }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.create")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.configRol.administrador.pestana.integrator.acciones.listar
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "tableFirst",
                attrs: {
                  items: _vm.itemsIntegrators,
                  fields: _vm.fieldsUsers,
                  stacked: "md",
                  "show-empty": "",
                  hover: "",
                  "table-variant": "light",
                  "sort-icon-left": "",
                  "select-mode": "single",
                  selectable: "",
                  borderless: "",
                  "tbody-tr-class": "table-users",
                  busy: _vm.isLoading
                },
                on: { "row-selected": _vm.onRowSelectedUser },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(actions)",
                      fn: function(row) {
                        return [
                          _vm.configRol.administrador.pestana.integrator
                            .acciones.eliminar
                            ? _c("b-icon", {
                                attrs: {
                                  icon: "trash",
                                  variant: "danger",
                                  "aria-hidden": "true"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(row)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-primary my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  512287826
                )
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("permissions.msgPermissionsIntegrator")))
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }