<template>
  <div role="group" class="mt-5">
    <b-row class="mb-4">
      <b-col offset="8" cols="4">
        <b-dropdown
          id="dropdown-integrator"
          menu-class="form-user"
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          ref="dropdown-integrator"
          class="m-2"
          v-if="validationDropdown"
        >
          <template #button-content>
            <b-button
              variant="outline-primary"
              class="button btn-blue-dark ml-0"
            >
              <span v-if="selectedIntegrator.length"
                >{{ $t("admin.integrator.editIntegrator") }} +</span
              >
              <span v-else
                >{{ $t("admin.integrator.createIntegrator") }} +</span
              >
            </b-button>
          </template>
          <b-dropdown-text class="my-3 text-header-user">
            <b-icon icon="person-plus" aria-hidden="true"></b-icon>
            <span v-if="selectedIntegrator.length">
              {{ $t("admin.integrator.editIntegrator") }}</span
            >
            <span v-else>{{ $t("admin.integrator.createIntegrator") }}</span>
          </b-dropdown-text>
          <b-dropdown-form>
            <b-form-input
              class="form-input-style"
              size="sm"
              :placeholder="this.$i18n.t('admin.integrator.company')"
              :state="validationCompany"
              v-model="form.company"
            ></b-form-input>

            <b-form-input
              class="form-input-style"
              size="sm"
              :placeholder="this.$i18n.t('admin.integrator.name')"
              :state="validationintegrator"
              v-model="form.integrador"
              :disabled="isEdit"
            ></b-form-input>
            <b-col class="justify-content-end buttons-dropdown">
              <b-button class="btn btn-blue-dark-outline ml-0" @click="cancel">
                {{ $t("admin.users.cancel") }}
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                @click="handleEditUser"
                :disabled="isLoading || !isValid"
                v-if="selectedIntegrator.length"
              >
                {{ $t("admin.users.save") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                @click="handleCreateUser"
                :disabled="isLoading || !isValid"
                v-else
              >
                {{ $t("admin.users.create") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
            </b-col>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- TABLE USERS-->
    <div v-if="configRol.administrador.pestana.integrator.acciones.listar">
      <b-table
      :items="itemsIntegrators"
      :fields="fieldsUsers"
      stacked="md"
      show-empty
      hover
      table-variant="light"
      class="tableFirst"
      sort-icon-left
      select-mode="single"
      selectable
      borderless
      tbody-tr-class="table-users"
      @row-selected="onRowSelectedUser"
      :busy="isLoading"
    >
      <template #cell(actions)="row">
        <b-icon
          icon="trash"
          variant="danger"
          aria-hidden="true"
          @click="handleDelete(row)"
          v-if="configRol.administrador.pestana.integrator.acciones.eliminar"
        ></b-icon>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    </div>
    <div v-else>
      <b-alert show dismissible>
          <p>{{ $t("permissions.msgPermissionsIntegrator") }}</p>
        </b-alert>
    </div>
    
  </div>
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import { AlertConfirm, AlertForm, ICON } from "../../helpers/Alert";
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "Integrator",
  components: {},
  data: () => ({
    itemsIntegrators: [],
    form: {
      company: "",
      integrador: "",
    },

    selectedIntegrator: [],
    isLoading: false,
    isEdit: false,
    configRol: {}
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.integrator.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.integrator.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    } 
  },
  async mounted() {
    try {
      await this.getIntegrators();
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    validationDropdown() {
        if(this.configRol.administrador.pestana.integrator.acciones.crear && this.selectedIntegrator.length === 0 ) {
          return true;
        } else if(this.configRol.administrador.pestana.integrator.acciones.editar && this.selectedIntegrator.length) {
          return true;
        }
        return false;
      },
    validationCompany() {
      return this.form.company.length > 3;
    },
    validationintegrator() {
      return this.form.integrador.length > 3;
    },
    isValid() {
      return this.form.company.length > 3 && this.form.integrador.length > 3;
    },
    fieldsUsers() {
      return [
        {
          key: "company",
          label: this.$i18n.t("admin.integrator.company"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "integrador",
          label: this.$i18n.t("admin.integrator.name"),
          sortable: true,
          sortDirection: "desc",
        },
        { key: "actions", label: "" },
      ];
    },
  },
  methods: {
    async getIntegrators() {
      try {
        this.isLoading = true;
        const data = await ApiRequest.get(`${API_ROOT}apicentral`);
        this.itemsIntegrators = data.listar;
      } catch (error) {
        this.itemsIntegrators = [];
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$refs["dropdown-integrator"].hide(true);
    },
    async handleCreateUser() {
      try {
        await ApiRequest.post(`${API_ROOT}apicentral`, {
          ...this.form,
          usuario: this.$store.state.userResponse.email,
        });
        AlertForm(
          this.$i18n.t("admin.integrator.success"),
          this.$i18n.t("admin.integrator.messageSuccess"),
          ICON.SUCCESS
        );
        this.$refs["dropdown-integrator"].hide(true);
        await this.getIntegrators();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.integrator.messageError"), ICON.ERROR);
      }
    },
    async handleEditUser() {
      try {
        await ApiRequest.put(`${API_ROOT}apicentral`, {
          ...this.form,
          usuario: this.$store.state.userResponse.email,
          id: this.selectedIntegrator[0]._id,
        });
        AlertForm(
          this.$i18n.t("admin.integrator.success"),
          this.$i18n.t("admin.integrator.messageSuccessEdit"),
          ICON.SUCCESS
        );
        this.$refs["dropdown-integrator"].hide(true);
        await this.getIntegrators();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.integrator.messageErrorEdit"), ICON.ERROR);
      }
    },

    async handleDelete(e) {
      AlertConfirm(
        this.$i18n.t("admin.integrator.delete"),
        `Name : ${e.item.company}`,
        ICON.WARNING,
        () => this.deleteConfirm(e.item._id)
      );
    },

    async deleteConfirm(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}apicentral`, {id});
        AlertForm(this.$i18n.t("admin.integrator.messageDelete"),
          "", ICON.SUCCESS );
        await this.getIntegrators();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.integrator.messageDeleteError"), ICON.ERROR);
      }
    },
    onRowSelectedUser(items) {
      this.selectedIntegrator = items;
      if (items.length) {
        const [integrator] = items;
        this.form.company = integrator.company || "";
        this.form.integrador = integrator.integrador || "";
      } else {
        this.form.company = "";
        this.form.integrador = "";
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";

/** FORM USER */
.form-user {
  font-weight: bold;
}
input.form-control {
  color: #333232;
  border: none;
}
/**menu dropdown */
#dropdown-integrator /deep/ .form-user {
  width: 400px;
}
#dropdown-integrator /deep/ .b-dropdown-text {
  color: #707070;
  font-weight: bolder;
}
#dropdown-integrator input.form-input-style,
#dropdown-integrator /deep/ .select {
  font-weight: bold;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #7070708a;
  margin: 15px 0;
}
#dropdown-integrator /deep/ .select {
  color: #707070c4;
}
#dropdown-integrator input.form-input-style::placeholder {
  color: #7070709d;
}
#dropdown-integrator input.form-input-style:focus {
  outline: none;
}
/**BUTTON STYLES */
.text-align-end {
  text-align: end;
}
#dropdown-integrator .buttons-dropdown {
  display: flex;
}
#dropdown-integrator .btn-blue-dark,
#dropdown-integrator .btn-blue-dark-outline {
  padding: 5px 10px;
  margin: 5px;
}
/**Table styles */
.tableFirst /deep/ .table-users {
  border-bottom: 1px solid rgb(160, 158, 158);
}
</style>